import React from "react";

function LinkedIn({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 38}
      height={height || 38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91667 4.75H30.0833C31.8323 4.75 33.25 6.16776 33.25 7.91667V30.0833C33.25 31.8323 31.8323 33.25 30.0833 33.25H7.91667C6.16776 33.25 4.75 31.8323 4.75 30.0833V7.91667C4.75 6.16776 6.16776 4.75 7.91667 4.75ZM12.6667 28.5C13.1039 28.5 13.4583 28.1455 13.4583 27.7083V16.625C13.4583 16.1878 13.1039 15.8333 12.6667 15.8333H10.2917C9.85445 15.8333 9.5 16.1878 9.5 16.625V27.7083C9.5 28.1455 9.85445 28.5 10.2917 28.5H12.6667ZM11.4792 14.25C10.1675 14.25 9.10417 13.1867 9.10417 11.875C9.10417 10.5633 10.1675 9.5 11.4792 9.5C12.7908 9.5 13.8542 10.5633 13.8542 11.875C13.8542 13.1867 12.7908 14.25 11.4792 14.25ZM27.7083 28.5C28.1455 28.5 28.5 28.1455 28.5 27.7083V20.425C28.5515 17.9088 26.6912 15.7613 24.1933 15.4533C22.4469 15.2938 20.7548 16.1095 19.7917 17.575V16.625C19.7917 16.1878 19.4372 15.8333 19 15.8333H16.625C16.1878 15.8333 15.8333 16.1878 15.8333 16.625V27.7083C15.8333 28.1455 16.1878 28.5 16.625 28.5H19C19.4372 28.5 19.7917 28.1455 19.7917 27.7083V21.7708C19.7917 20.4592 20.855 19.3958 22.1667 19.3958C23.4783 19.3958 24.5417 20.4592 24.5417 21.7708V27.7083C24.5417 28.1455 24.8962 28.5 25.3333 28.5H27.7083Z"
        fill={color || "#444"}
      />
    </svg>
  );
}

export default LinkedIn;
