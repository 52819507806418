const headerMenu = [
  {
    text: "Home",
    link: "/",
  },
  // {
  //   text: "Explore",
  //   link: "/explore",
  // },
  // {
  //   text: "Our Team",
  //   link: "/team",
  // },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "API",
    link: "/api",
  },
];
module.exports = headerMenu;
