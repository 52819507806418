import React, { useState, useEffect } from "react";

export default function Index() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <section className={`compareSection bg-gredient relative`}>
      <div className="container">
        <video
          height={800}
          loading="eagar"
          autoPlay
          loop
          muted
          playsInline
          className="video1 rounded-20to30"
        >
          <source src={"./videos/home/How_to_Desktop.mp4"} type={`video/mp4`} />
          Your browser does not support the video.
        </video>
        <video
          loading="eagar"
          autoPlay
          loop
          muted
          playsInline
          className="video2 rounded-20to30"
        >
          <source src={"./videos/home/How_to_MOBILE.mp4"} type={`video/mp4`} />
          Your browser does not support the video.
        </video>

        {/* <img src="./images/videobg.jpg" alt=""/> */}
      </div>
    </section>
  );
}
