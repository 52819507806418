import React from "react";
import Header from "../../components/Header";
import teamMembers from "../../Data/teamMembers";
import Picture from "../../components/Picture";
import Heading from "../../components/Heading";

export default function index() {
  return (
    <>
      <Header fixed className="bg-accent-lighter" />
      <main className="bg-accent-lighter">
        <Heading section text="Our Team" className="pt-20to30" />
        <section className="container grid gap-20to110 pb-50to100 md:grid-cols-2 lg:grid-cols-3">
          {teamMembers.map((member, index) => {
            return (
              <div
                className="h-260to440 overflow-hidden rounded-20to30 border border-accent-border text-center"
                key={index}
              >
                <h3 className="mb-1 pt-6 text-16to20 font-semibold lg:mb-2 lg:pt-7">
                  {member.name}
                </h3>
                <p className="mb-2 text-14to16 lg:mb-4">{member.role}</p>
                <Picture
                  className="mx-auto block h-auto"
                  altText={member.name}
                  mobile={member.imgMob}
                  desktop={member.imgDesk}
                  deskHeight="340"
                  deskWidth="340"
                  mobHeight="200"
                  mobWidth="200"
                />
              </div>
            );
          })}
        </section>
        <p className="container pb-10to24 text-center text-14to22 text-black-lighter">
          Developed by <br className="lg:hidden" />
          Prasad Lokhande and Prakhar Prashar
        </p>
      </main>
    </>
  );
}

//  <Picture
//    mobile="./Mobile/Riddhi.png"
//    desktop="./Desktop/Riddhi.png"
//    altText="Riddhi"
//  />;
