import React from "react";
import Header from "../../components/Header";
export default function Index() {
  return (
    <>
      <Header fixed />
      <main className="flex h-[50vh] flex-col items-center justify-center">
        <h1 className="text-9xl"> 404 </h1>
        <h3 className="text-5xl">Page not Found</h3>
      </main>
    </>
  );
}
