const RUGs = [
  {
    id: 1,
    title: "3D editing",
    videoType: "mp4",
    desktopVideo:
      "./Record-Upload-Generate/Desktop/3D-editing-Desktop/3D-Editing-Website.mp4",
    mobileVideo:
      "./Record-Upload-Generate/Mobile/3D-editing-Mobile/3D-Editing-for-mobile.mp4",
    mobilePoster:
      "./Record-Upload-Generate/Mobile/3D-editing-Mobile/3D-Editing-Image-Mobile.png",
    desktopPoster:
      "./Record-Upload-Generate/Desktop/3D-editing-Desktop/3D-Editing-Image-Mobile.png",
  },
  {
    id: 2,
    title: "E-commerce",
    videoType: "mp4",
    desktopVideo:
      "./Record-Upload-Generate/Desktop/Ecommerce-Desktop/Ecommerce-Website.mp4",
    mobileVideo:
      "./Record-Upload-Generate/Mobile/Ecommerce-Mobile/Ecommerce- Website-Mobile.mp4",
    mobilePoster:
      "./Record-Upload-Generate/Mobile/Ecommerce-Mobile/Ecommerce-Image-Mobile.png",
    desktopPoster:
      "./Record-Upload-Generate/Desktop/Ecommerce-Desktop/Ecommerce-Image-Desktop.png",
  },
  {
    id: 3,
    title: "Gaming",
    videoType: "mp4",
    desktopVideo:
      "./Record-Upload-Generate/Desktop/Gaming-Desktop/Gaming-For-Website.mp4",
    mobileVideo:
      "./Record-Upload-Generate/Mobile/Gaming-Mobile/Gaming- Website-Mobile.mp4",
    mobilePoster:
      "./Record-Upload-Generate/Mobile/Gaming-Mobile/Gaming-Image-Mobile.png",
    desktopPoster:
      "./Record-Upload-Generate/Desktop/Gaming-Desktop/Gaming-Image-Desktop.png",
  },
  {
    id: 4,
    title: "Interiors",
    videoType: "mp4",
    desktopVideo:
      "./Record-Upload-Generate/Desktop/Interior-Desktop/Interior-Website-Desktop.mp4",
    mobileVideo:
      "./Record-Upload-Generate/Mobile/Interior-Mobile/Interior-Website- Mobile.mp4",
    mobilePoster:
      "./Record-Upload-Generate/Mobile/Interior-Mobile/Interior-Website-Mobile.png",
    desktopPoster:
      "./Record-Upload-Generate/Desktop/Interior-Desktop/Interior-Image-Desktop.png",
  },
];
module.exports = RUGs;
