import React, { useEffect } from "react";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Team from "./pages/Team";
import ApiFront from "./pages/ApiFront";
import ComingSoon from "./pages/ComingSoon";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-T776H62JTS");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="team" element={<Team />} />
        <Route path="explore" element={<ComingSoon />} />
        <Route path="api" element={<ApiFront />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}
