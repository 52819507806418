import React from "react";

export default function Index({
  mobile,
  desktop,
  mobHeight,
  mobWidth,
  deskHeight,
  deskWidth,
  loading,
  breakPoint,
  altText,
  className,
  style,
}) {
  return (
    <picture>
      <source
        media={`(min-width:${breakPoint || 1024}px)`}
        srcSet={desktop}
        height={deskHeight}
        width={deskWidth}
      />
      <img
        src={mobile}
        height={mobHeight}
        width={mobWidth}
        className={className}
        style={style}
        alt={altText || "metashop"}
        loading={loading || "lazy"}
      />
    </picture>
  );
}
