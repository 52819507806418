import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import  ReactGA from "react-ga4";
export default function Index() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Explore",
      });
    }
  }, [location]);
  return (
    <>
      <Header fixed className="bg-accent-lighter" />
      <main className="flex h-[50vh] flex-col items-center justify-center bg-accent-lighter">
        <h3 className="text-5xl font-medium">Coming Soon</h3>
      </main>
    </>
  );
}
