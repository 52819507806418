import React from "react";

export default function Index({
  text,
  className,
  section,
  textLeft,
  sectionv2,
}) {
  return (
    <>
      <h2
        className={`font-bold ${
          section
            ? "mb-20to30 text-24to42"
            : sectionv2
            ? "mb-20to64 text-22to42"
            : "mb-20to60 text-24to54"
        } ${textLeft ? "text-left" : "text-center"} ${className}`}
      >
        {text}
      </h2>
    </>
  );
}
