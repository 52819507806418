import Card from "./Card";
import InstagramIcon from "../../icons/Instagram";
import LinkedInIcon from "../../icons/LinkedIn";
import TwitterIcon from "../../icons/Twitter";
import DiscordIcon from "../../icons/Discord";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";

export default function Index() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Contact Us",
      });
    }
  }, [location]);
  return (
    <>
      <Header fixed className="bg-accent-lighter" />
      <main className="bg-accent-lighter">
        <section className="container w-full">
          <Heading section text="Contact Us" className="pt-26to30" />
          <p className="mx-auto text-center text-12to18 font-medium ">
            We will be happy to answer any questions you may have.
          </p>
          <p className="mx-auto text-center text-12to18 font-medium ">
            {" "}
            We are just a click away
          </p>
        </section>

        <section
          className="container relative mx-auto py-30to50"
          style={{
            maxWidth: "clamp(16.25rem, -1.6346rem + 79.4872vw, 93.75rem)",
          }}
        >
          <div className="relative mx-auto flex h-full w-full flex-col items-center justify-center gap-30to60 lg:flex-row">
            <Card
              mobileIcon="./images/icons/mail-icon.png"
              desktopIcon="./images/icons/mail-icon.png"
              heading="Email"
              subContent={
                <a href="mailto:info@metashopai.com">info@metashopai.com</a>
              }
            />
            <Card
              mobileIcon="./images/icons/phone-call.png"
              desktopIcon="./images/icons/phone-call.png"
              heading="Call Us"
              subContent={<a href="tel:+917972876472">+91 7972876472</a>}
            />
            {/* <Card
              mobileIcon="./images/icons/like-mobile.png"
              desktopIcon="./images/icons/like.png"
              heading="Social Media"
              subContent={
                <div className="flex">
                  <a
                    href="https://discord.gg/8YNGyq4Ze4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DiscordIcon height="36" width="36" iconClassName="mx-1" />
                  </a>
                  <a
                    href="https://www.instagram.com/metashop.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon iconClassName="mx-2.5" />
                  </a>
                  <a
                    href="https://twitter.com/MetaShopApp?s=20"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon iconClassName="mx-2.5" />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/metashopapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon iconClassName="mx-2.5" />
                  </a>
                </div>
              }
            /> */}
          </div>
        </section>
      </main>
    </>
  );
}
