const solutionFor3dModals = [
  {
    mobileIcon: "./images/icons/swift.png",
    desktopIcon: "./images/icons/swift.png",
    title: "Swift and Cost-Savvy",
    description: "Finish projects 10x faster at 1/4th of cost",
  },
  {
    mobileIcon: "./images/icons/no-lidar.png",
    desktopIcon: "./images/icons/no-lidar.png",
    title: "No LiDAR needed",
    description:
      "NeRF technology eliminates the need for LiDAR or any other sensors",
  },
  {
    mobileIcon: "./images/icons/scan-surface.png",
    desktopIcon: "./images/icons/scan-surface.png",
    title: "Scan any surfaces",
    description:
      "Scan Textured, Rough, Smooth, and Shiny Surfaces without LiDAR/Laser",
  },
];
module.exports = solutionFor3dModals;
