const models3d = [
  {
    name: "Brand 1",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/IMG_8680.mov-nt3k8q-1697008571669.html",
  },
  {
    name: "Brand 2",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Spaceship_2386.html",
  },
  {
    name: "Brand 3",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Stansmith.html",
  },
  {
    name: "Brand 4",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Hulk_2.html",
  },
  {
    name: "Brand 5",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/skate_board.html",
  },
  {
    name: "Brand 6",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Spaceship_2467.html",
  },
  {
    name: "Brand 7",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Wall_E.html",
  },
  {
    name: "Brand 8",
    url: "https://storage.googleapis.com/metashop_demo_models/model-viewers/Spaceship_2575.html",
  },
];
module.exports = models3d;
