import React from "react";
import Picture from "../Picture";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

export default function Index({ className }) {
  const sendAnalytics = () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Try Now",
    });
    window.open("https://app.metashopai.com");
  };

  return (
    <section className={`relative py-117to300 text-center ${className}`}>
      <Picture
        mobile="./images/gradients/We-bring-3d-mobile.png"
        desktop="./images/gradients/We-bring-3d-desktop.png"
        mobHeight="420"
        mobWidth="400"
        deskHeight="970"
        deskWidth="1920"
        altText="gradiet"
        className="absolute top-0 left-0 -z-10 h-full w-full"
      />
      <h2 className="mb-36to100 p-3 text-44to80 font-semibold">
        Democratizing 3D for All
      </h2>
      <div className="ctsbottom">
        <button
          onClick={() => window.open("https://app.metashopai.com")}
          className="btnFill"
        >
          Try Now
        </button>
        <Link
          to="/api"
          // onClick={() =>
          //   window.open(
          //     "https://share.hsforms.com/1_GNXZYw_RH-s8c1FeFmmoQq8f6u"
          //   )
          // }
          className="btnOutline"
          // className="h-36to76 rounded-12to16 text-14to28 btnOutline"
        >
          Explore API
        </Link>
      </div>
    </section>
  );
}
