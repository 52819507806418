import Hero from "../../components/HeroVideo";
import Anything3d from "../../components/WeBring3d";
import Header from "../../components/Header";
import Tags from "../../components/Tags";
import SolutionFor3dModals from "../../components/SolutionFor3dModals";
import HomeAlert from "../../components/HomeAlert";
import ModelBrands from "../../components/Models3d";
import RecordUploadGenerate from "../../components/RecordUploadGenerate";
import CompareImage from "../../components/CompareImage";
import HowTo from "../../components/HowTo";
import { useLocation } from "react-router-dom";
import AwardsAndRecognition from "../../components/AwardsAndRecognition";
import ModalCards from "../../components/ModalCards";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import {
  fetchAllAwards,
  fetchCompanyList,
  fetchCustomersModal,
} from "../../actions/home";
export default function Index() {
  const location = useLocation();
  const [companyList, setCompanyList] = useState([]);
  const [customersModal, setCustomerModal] = useState([]);
  const [allAwards, setAllAwards] = useState([]);

  const getCompanyList = async () => {
    const res = await fetchCompanyList();
    if (res.message == "success") {
      setCompanyList(res.data.data);
    }
  };

  const getCustomerModels = async () => {
    const res = await fetchCustomersModal();
    if (res.message == "success") {
      setCustomerModal(res.data.data);
    }
  };

  const getAllAwards = async () => {
    const res = await fetchAllAwards();
    if (res.message == "success") {
      setAllAwards(res.data.data);
    }
  };

  useEffect(() => {
    if (location) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Home",
      });
    }
  }, [location]);

  useEffect(() => {
    getCompanyList();
    getCustomerModels();
    getAllAwards();
  }, []);

  return (
    <>
      <Header fixed />
      <main>
        {/* <HomeAlert /> */}
        <ModelBrands />
        <div className="upside_slider">
          <div class="row">
            {companyList &&
              companyList.map((value) => {
                return (
                  <div class="col-lg-2">
                    <div class="up_slider-item">
                      <div class="up_slide-img">
                        <img src={value?.image} alt="" class="upSlideimg" />
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div class="col-lg-2">
              <div class="up_slider-item">
                <div class="up_slide-img">
                  <img src="./images/stag.svg" alt="" class="upSlideimg" />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="up_slider-item">
                <div class="up_slide-img">
                  <img src="./images/Restly.svg" alt="" class="upSlideimg" />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="up_slider-item">
                <div class="up_slide-img">
                  <img src="./images/chupps.svg" alt="" class="upSlideimg" />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="up_slider-item">
                <div class="up_slide-img">
                  <img src="./images/artment.svg" alt="" class="upSlideimg" />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="up_slider-item">
                <div class="up_slide-img">
                  <img src="./images/lion.svg" alt="" class="upSlideimg" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div class="parntner_slider">
          <div className="row2">
            <div class="partner_supporting">Partners supporting our vision</div>
            <div class="partner_supporting">
              <img
                class="patnerSlide-img "
                src="./images/google_logo.png"
                alt=""
              />{" "}
              for Startups
            </div>
            <div class="partner_supporting">
              <img
                class="patnerSlide-img2 "
                src="./images/nvidia-inception.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <CompareImage />
        <RecordUploadGenerate />

        <SolutionFor3dModals />
        <HowTo />
        <ModalCards customersModal={customersModal} />
        <AwardsAndRecognition allAwards={allAwards} />
        {/* <Cards /> */}
        <Anything3d />
        {/* <Tags className="bg-accent-lighter" /> */}
      </main>
    </>
  );
}
