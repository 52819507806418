import React from "react";

function Instagram({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 38}
      height={height || 38}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.998 8.999A3.008 3.008 0 0 0 8.996 12a3.008 3.008 0 0 0 3.002 3.001 3.008 3.008 0 0 0 3-3.001 3.008 3.008 0 0 0-3-3.001zM20.999 12c0-1.243.011-2.474-.058-3.715-.07-1.441-.4-2.72-1.453-3.774-1.056-1.056-2.332-1.382-3.773-1.452-1.243-.07-2.475-.058-3.715-.058-1.243 0-2.475-.012-3.715.058-1.441.07-2.72.399-3.774 1.452C3.455 5.567 3.13 6.844 3.06 8.285c-.07 1.243-.058 2.474-.058 3.715 0 1.24-.012 2.474.058 3.715.07 1.441.399 2.72 1.452 3.774 1.056 1.055 2.333 1.382 3.774 1.452 1.243.07 2.474.058 3.715.058 1.243 0 2.474.012 3.715-.058 1.44-.07 2.72-.399 3.773-1.452 1.056-1.056 1.383-2.333 1.453-3.774.072-1.24.058-2.472.058-3.715zm-9.001 4.618A4.611 4.611 0 0 1 7.38 12a4.611 4.611 0 0 1 4.618-4.618A4.611 4.611 0 0 1 16.615 12a4.611 4.611 0 0 1-4.617 4.618zm4.807-8.347a1.077 1.077 0 0 1-1.079-1.078c0-.597.482-1.078 1.079-1.078a1.077 1.077 0 0 1 .762 1.841 1.078 1.078 0 0 1-.762.315z"
        fill={color || "#444"}
      />
    </svg>
  );
}

export default Instagram;
