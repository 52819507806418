import { requestWithToken } from "../helpers/helpers";
const fetchCompanyList = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      "/home/company?pageNo=1&pageSize=10"
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchCustomersModal = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      `/home/ourCustomer?pageNo=1&pageSize=10`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchAllAwards = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      `/home/award?pageNo=1&pageSize=10`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchCompanyList, fetchCustomersModal, fetchAllAwards };
