import React, { useState } from "react";
import MetashopLogoText from "../../icons/MetashopLogoText";
import BurgermenuIcon from "../../icons/Burgermenu";
import CloseIcon from "../../icons/Close";
import Button from "../Button";
import { NavLink, Link } from "react-router-dom";
import menuList from "../../Data/headerMenu";
export default function Index({ fixed, className }) {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <>
      <div
        className={`${
          navOpen ? "block" : "hidden"
        } fixed z-20 h-screen w-screen bg-transparent-7`}
        onClick={() => setNavOpen(false)}
      ></div>
      <header
        className={`${
          fixed ? "header static" : "absolute"
        } ${className} top-0 z-20 w-full`}
      >
        <div className="container flex w-full justify-between">
          <div className="flex items-center py-4">
            <BurgermenuIcon
              iconClassName="xl:hidden mr-7 xl:mr-unset"
              onClick={() => {
                setNavOpen(!navOpen);
              }}
            />
            <Link to="/">
              <MetashopLogoText iconClassName="h-32to56 w-166to294" />
            </Link>
          </div>

          <nav
            className={`${
              navOpen ? "opened" : ""
            } fixed -left-4/5 z-40 min-h-screen w-[260px] p-5 transition-all duration-500 opened:left-0 opened:bg-white md:p-7 xl:static xl:flex xl:min-h-0 xl:w-unset xl:max-w-none xl:items-center xl:justify-between xl:p-8 xl:px-0`}
          >
            <CloseIcon
              iconClassName="xl:hidden mb-10"
              onClick={() => {
                setNavOpen(!navOpen);
              }}
            />
            {menuList.map((menu, item) => {
              return (
                <NavLink
                  key={item}
                  to={menu.link}
                  className="my-3.5 block rounded-xl px-2.5 py-2 text-14to18 active:bg-accent-darker active:font-medium active:text-primary xl:mx-14to30 xl:inline-block xl:active:bg-transparent"
                >
                  {menu.text}
                </NavLink>
              );
            })}
            {/* <Button
              text="Request API"
              onClick={() => window.open("https://share.hsforms.com/1_GNXZYw_RH-s8c1FeFmmoQq8f6u")}
              className="btnOutline mobfull mb-3 lg:mb-0 xl:mx-14to30 xl:mr-0 xl:px-8"
            /> */}
            <Button
              text="Try Now"
              onClick={() => window.open("https://app.metashopai.com")}
              className="btnFill mobfull xl:mx-14to30 xl:mr-0 xl:px-8"
            />
          </nav>
        </div>
      </header>
    </>
  );
}
