import React, { useState, useEffect, useRef } from "react";
import Heading from "../../components/Heading";
import RUGs from "../../Data/RecordUploadGenerate";
import Picture from "../Picture";
import ReactGA from 'react-ga4'

export default function Index() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [currentTab, setCurrentTab] = useState("1");

  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    playVideo();
  }, []); 

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  useEffect(() => {
    if(currentTab ==1){
      ReactGA.send({
        hitType: "pageview",
        page: '/',
        title: "3D editing",
      });
    }
    else if(currentTab == 2){
      ReactGA.send({
        hitType: "pageview",
        page: '/',
        title: "E-Commerce",
      });
    }else if(currentTab == 3){
      ReactGA.send({
        hitType: "pageview",
        page: '/',
        title: "Gaming",
      });
    }
    else if(currentTab == 4){
      ReactGA.send({
        hitType: "pageview",
        page: '/',
        title: "Interior",
      });
    }
  }, [currentTab]);
  return (
    <section className="relative py-50to100 rugsec">
      {/* <Picture
        mobile="./images/gradients/RUG-mobile.png"
        desktop="./images/gradients/RUG-desktop.png"
        mobHeight="475"
        mobWidth="400"
        deskHeight="1017"
        deskWidth="1920"
        altText="gradiet"
        className="absolute top-0 left-0 -z-10 h-full w-full"
      /> */}
      <Heading text="Record-Upload-Generate 3D" sectionv2 />
      <div className="lg:container">
        <div className="tabs flex w-full gap-10to40 overflow-x-auto pb-34to50 md:justify-center">
          {RUGs.map((rug, i) => {
            return (
              <button
                key={i}
                id={rug.id}
                disabled={currentTab === `${rug.id}`}
                onClick={handleTabClick}
                className={`tab h-36to56 whitespace-nowrap rounded-12to16 border border-primary px-4 text-14to16 leading-extra-tight text-primary first:ml-2.5 disabled:cursor-pointer disabled:bg-primary disabled:text-white md:px-5 lg:px-6`}
              >
                {rug.title}
              </button>
            );
          })}
        </div>
        <div className="content">
          {RUGs.map((rug, i) => {
            return (
              <div key={i}>
                {currentTab === `${rug.id}` && (
                  <video
                    height={screenSize.width > 767 ? "800" : "600"}
                    ref={videoRef}
                    loading="eagar"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full"
                    poster={
                      screenSize.width > 767
                        ? rug.desktopPoster
                        : rug.mobilePoster
                    }
                  >
                    <source
                      src={
                        screenSize.width > 767
                          ? rug.desktopVideo
                          : rug.mobileVideo
                      }
                      type={`video/${rug.videoType}`}
                    />
                    Your browser does not support the video.
                  </video>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
