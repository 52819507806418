import React from "react";

function Twitter({ color, height, width, iconClassName }) {
  return (
    <svg
      className={iconClassName}
      width={width || 38}
      height={height || 38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4907 32.6801H8.51022C6.74842 32.6801 5.32031 31.252 5.32031 29.4902V8.50994C5.32031 6.74816 6.74842 5.32007 8.51022 5.32007H29.4904C31.2522 5.32007 32.6803 6.74816 32.6803 8.50994V29.4899C32.6806 31.252 31.2525 32.6801 29.4907 32.6801Z"
        fill={color || "#444"}
      />
      <path
        d="M9.52291 10.0867L16.8766 19.9193L9.47656 27.9135H11.1422L17.6211 20.9143L22.8556 27.9135H28.5233L20.7557 17.5279L27.6437 10.0867H25.9781L20.0116 16.5326L15.1906 10.0867H9.52291ZM11.9722 11.3137H14.5759L26.0737 26.6868H23.47L11.9722 11.3137Z"
        fill={color || "#EDF5F1"}
      />
    </svg>
  );
}

export default Twitter;
