import React from "react";
import Picture from "../Picture";
import ReactCompareImage from "react-compare-image";
import { Link } from "react-router-dom";
export default function Index() {
  return (
    <section className={`compareSection bg-gredient relative`}>
      {/* <Picture
        mobile="./images/gradients/We-bring-3d-mobile.png"
        desktop="./images/gradients/We-bring-3d-desktop.png"
        mobHeight="420"
        mobWidth="400"
        deskHeight="970"
        deskWidth="1920"
        altText="gradiet"
        className="absolute top-0 left-0 -z-10 h-full w-full"
      /> */}
      <div className="lg:container">
        <div className="compareOuter flex justify-between gap-10to110">
          <div className="compareBox">
            <div className="compareItem">
              <ReactCompareImage
                leftImage="./images/Othersbefore.png"
                rightImage="./images/MetaShopafter.png"
                sliderLineColor="#8fd7b3"
                sliderLineWidth="2"
              />
            </div>
          </div>
          <div className={`compTextBox mt-8 text-center lg:py-30to140`}>
            <h2 className="mb-14to30 w-full text-22to42 font-bold">
              Compare the photorealism <br />
              by yourself
            </h2>
            <div className="mx-auto flex gap-2 text-14to16 lg:gap-5">
              <button className="customBtn">Built with AI</button>
              <button className="customBtn">No manual intervention</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
