import React, { useRef } from "react";
import CustomCarousel from "../carousal/carousal";
import ArrowPrevSlideIcon from "../../icons/ArrowPrevSlide";
import ArrowNextSlideIcon from "../../icons/ArrowNextSlide";

const ModalCards = ({ customersModal }) => {
  const childRef = useRef(null);
  const nextSlid = () => {
    // Check if the child component reference exists
    if (childRef.current) {
      // Call the child component's function
      childRef.current.nextSlid();
    }
  };

  const prevSlid = () => {
    // Check if the child component reference exists
    if (childRef.current) {
      // Call the child component's function
      childRef.current.prevSlid();
    }
  };
  return (
    <section class="revolutionized">
      <div className="revolution_title">Testimonials</div>
      {/* <div className="revolution_title_mob">
        We've revolutionized the world of 3D creation hear it from our
        customers!
      </div> */}
      <div className="award_cards" style={{ position: "relative" }}>
        <div className="slider_btns">
          <button
            onClick={() => {
              prevSlid();
            }}
          >
            <ArrowPrevSlideIcon
              iconClassName="brandPrev h-36to56 w-36to56"
              id="deskBrandPrev"
              height="36"
              width="36"
            />
          </button>
          <button
            onClick={() => {
              nextSlid();
            }}
          >
            <ArrowNextSlideIcon
              height="36"
              width="36"
              iconClassName="brandNext h-36to56 w-36to56"
              id="deskBrandNext"
            />
          </button>
        </div>
        <CustomCarousel showCardCount={3} ref={childRef} extraClass="sld1">
          {customersModal.length &&
            customersModal?.map((value) => {
              return (
                <div class="revolution_card-box">
                  <div class="revolution_card">
                    <div class="revolution_img-box">
                      <div class="revolution_img">
                        <img src={value.image} alt="" />
                      </div>
                      <div className="quotes">
                        <img src="./images/quote.svg" alt="" />
                      </div>
                    </div>
                    <div class="revolution_text-content">
                      <div class="revolution_logo">
                        <img src={value.icon} alt="" />
                      </div>
                      <div class="revolution_desc">{value.desc}</div>
                      <div class="revolution_text"> {value.name}</div>
                      <div class="revolution_desc"> {value.designation}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </CustomCarousel>
      </div>

      {/* <div class="revolution_card-box">
              <div class="revolution_card">
                <div class="revolution_img-box">
                  <div class="revolution_img">
                    <img src="./images/shoe.svg" alt="" />
                  </div>
                  <div className="quotes">
                    <img src="./images/quote.svg" alt="" />
                  </div>
                </div>
                <div class="revolution_text-content">
                  <div class="revolution_logo">
                    <img src="./images/chupps_logo.svg" alt="" />
                  </div>
                  <div class="revolution_desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibhmauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magnaeget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id nihgara tuer
                    nectar esar hartim augue.
                  </div>
                  <div class="revolution_text">Name goes here</div>
                  <div class="revolution_desc">Designation</div>
                </div>
              </div>
            </div>
            <div class="revolution_card-box">
              <div class="revolution_card">
                <div class="revolution_img-box">
                  <div class="revolution_img">
                    <img src="./images/sofa_image.svg" alt="" />
                  </div>
                  <div className="quotes">
                    <img src="./images/quote.svg" alt="" />
                  </div>
                </div>
                <div class="revolution_text-content">
                  <div class="revolution_logo">
                    <img src="./images/chupps_logo.svg" alt="" />
                  </div>
                  <div class="revolution_desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibhmauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magnaeget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id nihgara tuer
                    nectar esar hartim augue.
                  </div>
                  <div class="revolution_text">Name goes here</div>
                  <div class="revolution_desc">Designation</div>
                </div>
              </div>
            </div> */}
    </section>
  );
};

export default ModalCards;
