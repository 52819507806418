import React from "react";

function Discord({ color, height, width, iconClassName, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "30"}
      height={height || "30"}
      viewBox="0 0 30 30"
      fill="none"
      className={iconClassName}
      onClick={onClick}
    >
      <path
        d="M24.024 6.759A20.544 20.544 0 0 0 18.66 5c-.253.483-.482.98-.687 1.488a18.759 18.759 0 0 0-5.953 0A16.224 16.224 0 0 0 11.333 5c-1.853.337-3.658.93-5.368 1.763C2.571 12.116 1.65 17.335 2.11 22.48A21.137 21.137 0 0 0 8.69 26a17.154 17.154 0 0 0 1.408-2.421 13.637 13.637 0 0 1-2.219-1.13c.186-.143.368-.291.544-.435A14.663 14.663 0 0 0 15 23.58c2.274 0 4.52-.535 6.577-1.566.177.155.36.303.544.436-.709.446-1.453.824-2.223 1.13.404.848.876 1.658 1.409 2.42a21.042 21.042 0 0 0 6.583-3.517c.54-5.967-.923-11.139-3.866-15.724zM10.681 19.316c-1.282 0-2.342-1.24-2.342-2.766 0-1.525 1.023-2.776 2.338-2.776 1.315 0 2.366 1.25 2.343 2.776-.022 1.526-1.032 2.766-2.34 2.766zm8.638 0c-1.284 0-2.34-1.24-2.34-2.766 0-1.525 1.023-2.776 2.34-2.776s2.36 1.25 2.337 2.776c-.022 1.526-1.03 2.766-2.337 2.766z"
        fill={color || "#444"}
      />
    </svg>
  );
}

export default Discord;
