const teamMembers = [
  {
    name: "Sophiya Jagannathan",
    role: "Co-Founder and CEO",
    imgMob: "./images/team/Mobile/Sophiya.png",
    imgDesk: "./images/team/Desktop/Sophiya.png",
  },
  {
    name: "Mukul Ingle",
    role: "Co-Founder and CTO",
    imgMob: "./images/team/Mobile/Mukul.png",
    imgDesk: "./images/team/Desktop/Mukul.png",
  },
  {
    name: "Manoj Reddy",
    role: "Deep Learning Engineer and Researcher",
    imgMob: "./images/team/Mobile/Manoj.png",
    imgDesk: "./images/team/Desktop/Manoj.png",
  },
  {
    name: "Guru Prasath",
    role: "Finance and Business Development Manager",
    imgMob: "./images/team/Mobile/Guru.png",
    imgDesk: "./images/team/Desktop/Guru.png",
  },
  {
    name: "Riddhi Jadhav",
    role: "UI/UX Designer",
    imgMob: "./images/team/Mobile/Riddhi.png",
    imgDesk: "./images/team/Desktop/Riddhi.png",
  },
  {
    name: "Sandesh Gadakh",
    role: "3D Artist and Researcher",
    imgMob: "./images/team/Mobile/Sandesh.png",
    imgDesk: "./images/team/Desktop/Sandesh.png",
  },
  {
    name: "Bharath Jagannathan",
    role: "Sales and Marketing Associate",
    imgMob: "./images/team/Mobile/Bharath.png",
    imgDesk: "./images/team/Desktop/Bharath.png",
  },
  {
    name: "Vrushabh Mahajan",
    role: "Sales and Marketing Associate",
    imgMob: "./images/team/Mobile/Vrushabh.png",
    imgDesk: "./images/team/Desktop/Vrushabh.png",
  },
];

module.exports = teamMembers;
