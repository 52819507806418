import React from "react";

function ArrowPrevSlide({ iconClassName, height, width, color, onClick }) {
  return (
    <svg
      className={`cursor-pointer ${iconClassName}`}
      onClick={onClick}
      width={width || "56"}
      height={height || "56"}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-.5"
        y=".5"
        width="55"
        height="55"
        rx="11.5"
        transform="matrix(-1 0 0 1 55 0)"
        stroke={color || "#137359"}
      />
      <path
        d="M41 28H15M24.533 18 15 28l9.533 10"
        stroke={color || "#137359"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowPrevSlide;
