import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import solutionFor3dModals from "../../Data/solutionFor3dModals";
import Heading from "../Heading";
import Picture from "../Picture";
export default function Index() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  if (screenSize.width < 1024) {
    return (
      <section
        className={`${
          screenSize.width < 1024 ? "bg-accent-lighter" : null
        } py-50to100`}
      >
        <Heading
          sectionv2
          text="One-Stop solution for 3D assets"
          className="px-12"
        />
        <Swiper
          className="w-full"
          slidesPerView={1.2}
          spaceBetween={10}
          modules={[Pagination, EffectCoverflow]}
          loop={true}
          centeredSlides={true}
          pagination={{
            el: "#solution3dPagination",
            clickable: true,
            dynamicBullets: true,
            renderBullet: function (index, className) {
              return `<span class="${className} transition-all h-2 w-2 mx-1 rounded-full inline-block bg-accent"></span>`;
            },
          }}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 0,
            slideShadows: false,
          }}
        >
          {solutionFor3dModals.map((solution, item) => {
            return (
              <SwiperSlide key={item}>
                <div
                  className="flex h-260to440 w-full flex-col justify-center rounded-20to30 border border-accent-border py-40to80 px-15to70 text-center"
                  key={item}
                >
                  <Picture
                    mobile={solution.mobileIcon}
                    desktop={solution.desktopIcon}
                    className="mx-auto mb-40to70 block h-54to104 w-54to104"
                  />
                  <h3 className="mb-20to30 text-18to20 font-semibold">
                    {solution.title}
                  </h3>
                  <p className="text-14to16">{solution.description}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          id="solution3dPagination"
          className="mx-auto mt-3 text-center lg:hidden"
        ></div>
      </section>
    );
  } else {
    return (
      <>
        <section className="container my-50to100">
          <Heading sectionv2 text="One-Stop solution for 3D assets" />
          <div className="flex justify-between gap-10to110">
            {solutionFor3dModals.map((solution, item) => {
              return (
                <div
                  className="flex h-260to440 w-300to500 flex-col justify-center rounded-20to30 border border-accent-border px-15to70 text-center"
                  key={item}
                >
                  <Picture
                    mobile={solution.mobileIcon}
                    desktop={solution.desktopIcon}
                    className="mx-auto mb-40to70 block"
                  />
                  <h3 className="mb-20to30 text-18to20 font-semibold">
                    {solution.title}
                  </h3>
                  <p className="text-14to16">{solution.description}</p>
                </div>
              );
            })}
          </div>
        </section>
      </>
    );
  }
}
