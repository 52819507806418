const tags = [
  "XR experiences to millions faster",
  "Scan with any device anywhere",
  "NeRF to mesh",
  "Android",
  "iOS",
  "Drone",
  "DSLR",
  "No LiDAR Needed",
  "Photorealistic 3D assets",
  "Download and Customize",
  "Copy link to PDP",
  "XR experiences to millions faster",
  "Scan with any device anywhere",
  "NeRF to mesh",
  "Android",
  "iOS",
  "Drone",
  "DSLR",
  "No LiDAR Needed",
  "Photorealistic 3D assets",
  "Download and Customize",
  "Copy link to PDP",
  "XR experiences to millions faster",
  "Scan with any device anywhere",
  "NeRF to mesh",
  "Android",
  "iOS",
  "Drone",
  "DSLR",
  "No LiDAR Needed",
  "Photorealistic 3D assets",
  "Download and Customize",
  "Copy link to PDP",
  "XR experiences to millions faster",
  "Scan with any device anywhere",
  "NeRF to mesh",
  "Android",
  "iOS",
  "Drone",
  "DSLR",
  "No LiDAR Needed",
  "Photorealistic 3D assets",
  "Download and Customize",
  "Copy link to PDP",
];
module.exports = tags;
