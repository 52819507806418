import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import ReactGA from "react-ga4";
import { InlineWidget } from "react-calendly";

export default function Index() {
  const location = useLocation();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const sendAnalytics = (url) => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "API",
    });
    window.open(url);
  };
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <>
      <Header fixed className="bg-accent-lighter" />
      <main className="bg-accent-lighter">
        <section className="apiSection">
          <div className="lg:container">
            <div className="apiSectionContaint xl:gap:15 gap-10">
              <div className="textBlock">
                <h3 className="heading1 mob-center">Video to 3D API</h3>
                <div className="contentApi">
                  <p>
                    MetaShop's API requires a video recording of a subject or a
                    scene, providing a complete 360-degree view captured from
                    three distinct angles: top, middle, and bottom.
                  </p>
                  <ul className="apili">
                    The API generates an interactive 3D model that offers the
                    following functionalities
                    <li>
                      Download Formats: Available in .glb, .usdz, .ply, and .obj
                      formats
                    </li>
                    <li>
                      Shareable Embed Link: Get an iframe link for the 3D model,
                      making it easy to embed on websites or share with others
                      for viewing.
                    </li>
                  </ul>
                  <p>
                    This API can be seamlessly integrated into web applications
                    or mobile apps, enabling users to upload videos directly
                    from their chosen platform, without any user interface
                    constraints.
                  </p>

                  <div className="btnBx mt-8 flex gap-2 lg:gap-4">
                    <Link
                      className="btnFill"
                      onClick={() => {
                        sendAnalytics(
                          "https://share.hsforms.com/1_GNXZYw_RH-s8c1FeFmmoQq8f6u"
                        );
                      }}
                    >
                      Request API and Pricing
                    </Link>
                    {/* <Link className="btnOutline">Docs</Link> */}
                  </div>
                </div>
              </div>
              <div className="threeDBOxContainer">
                <video
                  height={530}
                  width={650}
                  loading="eagar"
                  autoPlay
                  loop
                  muted
                  className="w-full rounded-20to30"
                >
                  <source
                    src={"./videos/APISECTIONVIDEO.mp4"}
                    type={`video/mp4`}
                  />
                  Your browser does not support the video.
                </video>
              </div>
            </div>
            {/* <InlineWidget url="https://calendly.com/metashopai/video-to-3d-api-access" /> */}
          </div>
        </section>
      </main>
    </>
  );
}
