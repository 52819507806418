import React from "react";
import Picture from "../../../components/Picture";
export default function Index({
  icon,
  heading,
  subContent,
  mobileIcon,
  desktopIcon,
}) {
  return (
    <div className="flex w-300to460 flex-col items-center justify-center rounded-7.5 border py-40to70">
      <Picture
        loading="eagar"
        className="mb-20to30"
        altText="icon"
        mobile={mobileIcon}
        desktop={desktopIcon}
        mobHeight="54"
        mobWidth="54"
        deskHeight="104"
        deskWidth="104"
      />
      <h5 className="mb-20to30 text-18to20 font-bold">{heading}</h5>
      <p className="text-sm font-medium">{subContent}</p>
    </div>
  );
}
