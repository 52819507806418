import React from "react";

export default function Index({
  text,
  outline,
  small,
  className,
  onClick,
  Icon,
  iconColor,
  iconHeight,
  iconWidth,
  iconClassName,
}) {
  return (
    <>
      <button
        onClick={onClick}
        className={`whitespace-nowrap rounded-xl leading-extra-tight ${className} ${
          Icon && "flex items-center justify-center"
        } ${
          outline
            ? "border border-primary text-primary"
            : "bg-primary text-white"
        } ${small ? "h-30to40" : "h-36to56"}`}
      >
        {Icon ? (
          <Icon
            iconClassName={`${text && "mr-2"} ${iconClassName}`}
            color={outline ? "#137359" : iconColor}
            height={iconHeight}
            width={iconWidth}
          />
        ) : (
          false
        )}
        {text}
      </button>
    </>
  );
}
