import React from "react";
import MetashopLogoText from "../../icons/MetashopLogoText";
import InstagramIcon from "../../icons/Instagram";
import LinkedInIcon from "../../icons/LinkedIn";
import DiscordIcon from "../../icons/Discord";
import TwitterIcon from "../../icons/Twitter";
import { Link } from "react-router-dom";
import Picture from "../Picture";
import footerMenu from "../../Data/footerMenu";
import ReactGA from "react-ga4";

export default function Index() {
  const sendAnalytics = (url) => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Social Media Icons",
    });
    window.open(url);
  };

  return (
    <>
      <footer className="border-t border-accent-border bg-accent-lighter">
        <div className="container">
          <section className="flex flex-col items-center justify-evenly py-30to60">
            <Link to="/">
              <MetashopLogoText
                height="46"
                width="243"
                iconClassName="w-190to243 h-36to46"
              />
            </Link>
            <div className="mt-40to70 mb-40to80 w-full lg:flex lg:w-auto xl:max-w-4/5">
              <div className="mb-5 flex justify-evenly  lg:m-unset">
                {footerMenu.slice(0, 3).map((menu, index) => {
                  return (
                    <Link
                      to={menu.link}
                      className="text-14to16 lg:mx-6"
                      key={index}
                    >
                      {menu.text}
                    </Link>
                  );
                })}
              </div>
              <div className="mt-5 flex justify-evenly lg:m-unset">
                {footerMenu.slice(3, 6).map((menu, index) => {
                  return (
                    <Link
                      to={menu.link}
                      className="text-14to16 lg:mx-6"
                      key={index}
                    >
                      {menu.text}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="footerIcon flex">
              {/* <FacebookIcon iconClassName="mx-1" /> */}
              <p
                className="cursor-pointer"
                onClick={() => {
                  sendAnalytics("https://www.instagram.com/metashop.ai/");
                }}
                // href="https://www.instagram.com/metashop.ai/"
                // target="_blank"
                // rel="noreferrer"
              >
                <InstagramIcon iconClassName="mx-2" />
              </p>
              <p
                className="cursor-pointer"
                onClick={() => {
                  sendAnalytics("https://twitter.com/MetaShopApp?s=20");
                }}
                // href="https://twitter.com/MetaShopApp?s=20"
                // target="_blank"
                // rel="noreferrer"
              >
                <TwitterIcon iconClassName="mx-2" />
              </p>
              <p
                className="cursor-pointer"
                onClick={() => {
                  sendAnalytics("https://discord.gg/8YNGyq4Ze4");
                }}
                // href="https://discord.gg/8YNGyq4Ze4"
                // target="_blank"
                // rel="noreferrer"
              >
                <DiscordIcon height="36" width="36" iconClassName="mx-2" />
              </p>
              <p
                className="cursor-pointer"
                onClick={() => {
                  sendAnalytics(
                    "https://www.linkedin.com/company/metashopapp/"
                  );
                }}
                // href="https://www.linkedin.com/company/metashopapp/"
                // target="_blank"
                // rel="noreferrer"
              >
                <LinkedInIcon iconClassName="mx-2" />
              </p>
            </div>
          </section>
        </div>
        <section className="border-t border-accent-border">
          <div className="container pt-5 pb-2.5 text-center lg:flex lg:flex-wrap lg:items-center lg:justify-between lg:py-5">
            <div className="mb-10 lg:order-2 lg:m-unset lg:flex lg:items-center lg:text-right">
              <h6 className="mb-2.5 text-16to20 font-semibold text-black lg:m-unset lg:mr-7">
                Backed By
              </h6>
              <Picture
                className="mx-auto block"
                mobile="./images/kxx-mobile.webp"
                desktop="./images/kxx.webp"
                mobWidth="90"
                mobHeight="50"
                deskHeight="72"
                deskWidth="142"
              />
            </div>
            <h6 className="text-xs lg:text-sm">
              Copyright © {new Date().getFullYear()} MetaShop
            </h6>
          </div>
        </section>
      </footer>
    </>
  );
}
