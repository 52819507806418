import React from "react";

function ArrowNextSlide({ iconClassName, height, width, color, onClick }) {
  return (
    <svg
      className={`cursor-pointer ${iconClassName}`}
      onClick={onClick}
      width={width || "56"}
      height={height || "56"}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x=".5" y=".5" width="55" height="55" rx="11.5" stroke="#137359" />
      <path
        d="M15 28h26M31.467 18 41 28l-9.533 10"
        stroke={color || "#137359"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowNextSlide;
